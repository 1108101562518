import React from "react";

import landingOne from "../../images/landing/landing-1.jpg";
import landingTwo from "../../images/landing/landing-2.jpg";

export default function Landing({ setSelectedFlow }) {
  return (
    <div className="landing">
      <div onClick={() => setSelectedFlow("ortho")} className="image-container">
        <img src={landingTwo} alt="Grown-up" />
        <div className="overlay">
          <div className="overlay__container">
            <p className="overlay__left">Kieferorthopädie</p>
            <p className="overlay__subtext">für jedes Alter</p>
          </div>
        </div>
      </div>
      <div
        onClick={() => setSelectedFlow("pediatric")}
        className="image-container"
      >
        <img src={landingOne} alt="Child" />
        <div className="overlay">
          <div className="overlay__container">
            <p>Kinder- und Jugendzahnheilkunde</p>
            <p className="overlay__subtext">von 4 bis 17 Jahre</p>
          </div>
        </div>
      </div>
    </div>
  );
}
