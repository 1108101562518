import React from "react";
import Banner from "../elements/Banner";
import BookingButton from "../elements/BookingButton";

import insurance from "../../images/insurance.jpg";
import redhead from "../../images/redhead.jpg";
import useWindowDimensions from "../../useWindowDimensions";
import PageTitle from "../elements/PageTitle";

export default function Reimbursement() {
  const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="Kostenerstattung"
        description="In einigen Tarifen einer Zahnzusatzversicherung sind Zuzahlungen
              bei Kassenbehandlungen und/oder rein private kieferorthopädische
              Behandlungen versichert. Bitte fragen Sie Ihre private
              Zahnzusatzversicherung, ob und in welchem Umfang bei Ihnen bzw.
              Ihrem Kind die Kieferorthopädie abgedeckt ist. Wir empfehlen
              jedoch grundsätzlich dringend, vor jeder Behandlung den von uns
              erstellten Heil- und Kostenplan vorab bei der
              Zahnzusatzversicherung für eine Deckungszusage vorzulegen."
        canonical="kostenerstattung"
      />
      <div className="reimbursement-page page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Kostenerstattung">
            {width < 520 ? (
              <>
                Kostener-
                <br />
                stattung
              </>
            ) : (
              "Kostenerstattung"
            )}
          </Banner>
          <div className="container">
            <h3 className="text-center">Erstattung privater Behandlungen</h3>

            <h5 className="text-secondary mt-50">Zahnzusatzversicherung</h5>
            <p className="text-justify">
              In einigen Tarifen einer Zahnzusatzversicherung sind Zuzahlungen
              bei Kassenbehandlungen und/oder rein private kieferorthopädische
              Behandlungen versichert. Bitte fragen Sie Ihre private
              Zahnzusatzversicherung, ob und in welchem Umfang bei Ihnen bzw.
              Ihrem Kind die Kieferorthopädie abgedeckt ist. Wir empfehlen
              jedoch grundsätzlich dringend, vor jeder Behandlung den von uns
              erstellten Heil- und Kostenplan vorab bei der
              Zahnzusatzversicherung für eine Deckungszusage vorzulegen.
            </p>
            <h5 className="text-secondary">Beihilfe</h5>

            <p className="text-justify">
              In der Regel bezahlt die Beihilfe eine kieferorthopädische
              Behandlung mit dem Invisalign-System bei Kindern unter 18 Jahren.
            </p>

            <p className="text-justify">
              Erwachsene haben selten die Möglichkeit, in den Genuss einer
              Kostenübernahme durch die Beihilfe zu kommen. Ausschließlich in
              sehr schwerwiegenden Fällen, bei denen zusätzlich eine
              kieferchirurgische Maßnahme zum Umstellen der Kiefer notwendig
              ist, kann eine Kostenübernahme durch die Beihilfe erfolgen.
            </p>

            <p className="text-justify">
              Ausführliche Informationen erhalten Sie bei Ihrer Beihilfestelle.
              Wir empfehlen jedoch grundsätzlich dringend, vor jeder Behandlung
              den von uns erstellten Heil- und Kostenplan vorab bei der Beihilfe
              für eine Deckungszusage vorzulegen.
            </p>
            <h5 className="text-secondary">Private Krankenversicherung</h5>
            <p className="text-justify">
              In der Regel bezahlen die privaten Krankenversicherungen eine
              kieferorthopädische Behandlung mit dem Invisalign-System bei
              Kindern unter 18 Jahren. In vielen Tarifen der privaten
              Krankenversicherungen sind auch kieferorthopädische Behandlungen
              bei Erwachsenen versichert.
            </p>
            <p className="text-justify">
              Ausführliche Informationen erhalten Sie bei Ihrer privaten
              Krankenversicherung. Wir empfehlen jedoch grundsätzlich dringend,
              vor jeder Behandlung den von uns erstellten Heil- und Kostenplan
              vorab Ihrer privaten Krankenversicherung für eine Deckungszusage
              vorzulegen.
            </p>
            <img
              src={insurance}
              className="mt-20"
              style={{
                borderRadius: "2rem",
              }}
            />
            <BookingButton
              text="Buchen Sie jetzt einen persönlichen Beratungstermin!"
              classes="btn-primary mx-auto mt-30 d-block justify-self-center"
            />
          </div>

          <hr className="line w-100 mt-50 mb-50 text-secondary" />

          <div className="container">
            <h3>Probleme bei der Erstattung</h3>
            <h4>
              Offizielle Stellungnahme der Zahnärztekammer Niedersachsen
              (12/2022)
            </h4>
            <div className="mt-40">
              <p className="text-justify">
                Wussten Sie eigentlich, dass die{" "}
                <strong className="fw-700">
                  Gebührenordnung für Zahnärzte (GOZ) seit 1988 einen
                  unveränderten Punktwert
                </strong>{" "}
                aufweist? Dem Punktwert kommt in der GOZ die Bedeutung zu, die{" "}
                <strong>
                  allgemeine wirtschaftliche Entwicklung (Preissteigerungen)
                  abzubilden
                </strong>
                . Zuständig und verantwortlich für diese Anpassung ist allein{" "}
                <strong>
                  der Gesetzgeber, der dieser Verpflichtung seit 34 Jahren
                  leider nicht nachkommt.{" "}
                </strong>
                In den Gebührenordnungen anderer vergleichbarer freier Berufe,
                zuletzt bei den Tierärzten und den Rechtsanwälten, sind jedoch
                Anpassungen erfolgt. Ganz abgesehen von den Erhöhungen der
                Diäten, die sich die Abgeordneten selbst regelmäßig geben.
              </p>
              <p className="text-justify">
                <strong>
                  In den Jahren 1988 - 2021 betrug die allgemeine
                  Preissteigerung 66 %.
                </strong>
                Seit Corona steigen die Kosten für Zahnarztpraxen massiv; in
                diesem Jahr aus bekannten Gründen noch verstärkt. Ein Beispiel:
                Wenn in unserer Zahnarztpraxis bei Ihnen ein "normaler"
                einwurzeliger Zahn entfernt worden ist, bekommen Sie dafür 9,05
                € berechnet. Dieses Geld ist aber nicht gleich Einkommen zur
                freien Verfügung, sondern nur der Praxisumsatz. Davon müssen
                alle in der Praxis anfallenden Kosten bezahlt werden. Nach Abzug
                dieser Kosten verbleiben nach statistischen Mittelwerten rund
                2,75 €, die selbstverständlich noch zu versteuern sind und von
                denen auch noch arzteigene Beiträge zur Krankenversicherung und
                Altersvorsorge getragen werden müssen.
              </p>
              <p className="text-justify">
                In den vergangenen Jahren haben wir in unserer Praxis in Ihrem
                Interesse trotz dieser Kostenspirale darauf verzichtet, durch
                gebührenrechtliche Möglichkeiten außerhalb des Steigerungssatzes
                unsere Leistungspreise anzuheben und stattdessen die
                Kostensteigerungen selbst getragen. Dies anfangs auch, weil vor
                34 Jahren die privatzahnärztlichen Leistungen preislich noch
                generell oberhalb der Sozialversicherungssätze lagen.{" "}
                <strong>
                  Inzwischen liegen rund 50 % dieser Leistungspreise unter denen
                  der Sozialhilfe!
                </strong>{" "}
                Die aktuell in allen Lebensbereichen spürbaren hohen
                Kostensteigerungen zwingen auch unsere Zahnarztpraxis jetzt
                dazu, die gebührenrechtlichen Möglichkeiten zur Kostenabfederung
                anzuwenden.
              </p>
              <p className="text-justify">
                Sofern Sie sich Kosten unserer Liquidation durch eine private
                Krankenversicherung und/oder Ihre Beihilfestelle erstatten
                lassen wollen, können
                <strong>
                  diese gebührenrechtlich zulässig angewandten Möglichkeiten
                  dazu führen, dass Sie einen Anteil oder einen höheren
                  Selbstbehalt als bisher gewohnt aus den Kosten für Ihre
                  Behandlung selbst tragen müssen.
                </strong>
                Ursache hierfür können versicherungsvertragliche und/oder
                beihilferechtliche Regelungen oder auch ein restriktives
                Erstattungsverhalten Ihrer Krankenkasse/Beihilfe sein.
              </p>
              <p className="text-justify">
                Weiterhin ist Ihr Interesse, bei uns gemäß den Erkenntnissen
                moderner Zahnheilkunde behandelt zu werden, die Motivation und
                der Antrieb unseres Praxisteams für Ihre Behandlungen.{" "}
                <strong>
                  Das ist zu den Preisen von 1988 endgültig nicht mehr möglich.
                </strong>{" "}
                Es ist eine an die allgemeine Preisentwicklung angepasste
                Leistungsvergütung erforderlich, um die Zukunft nicht unserer
                Praxis als Ihre Zahnarztpraxis, sondern aller deutschen
                Zahnarztpraxen zu gewährleisten.
              </p>
              <p className="text-justify">
                Die Schuld an der Sie und uns als Ihre Zahnarztpraxis aktuell
                besonders belastenden Situation trägt einzig und allein der
                Gesetzgeber.
              </p>
            </div>
            <a href="/PatientenbriefKostensteigerung.pdf" download>
              <button
                // onClick={() => {
                //   (window.location.href =
                //     "../../PatientenbriefKostensteigerung.pdf"),
                // }}
                className="btn btn-secondary d-block mx-auto mt-30"
              >
                Hier geht es zum offiziellen Dokument
              </button>
            </a>
          </div>

          <hr className="line w-100 mt-50 mb-50 text-secondary" />

          <div className="container mt-50">
            <h3>Wir lassen Sie nicht allein!</h3>
            <h3>Der BFS Erstattungsservice</h3>
            <div className="mt-40">
              <p className="text-justify">
                Wir weisen an dieser Stelle ebenfalls offen darauf hin, dass die
                Beihilfe erfahrungsgemäß die mit Abstand schwierigste
                Kostenerstatterin ist, wenn es um Berechnungen oberhalb des
                2,3-fachen Satzes trotz rechtskonformer Begründungen geht.
              </p>
              <p className="text-justify">
                Doch auch vereinzelte private Krankenversicherungen handhaben
                die Erstattungen inzwischen restriktiv, wenn oberhalb des
                2,3-fachen Satzes berechnet wird.
              </p>
              <p className="text-justify">
                Unser Abrechnungsservice BFS health finance steht Ihnen in
                solchen Fällen zwar mit starken Stellungnahmen, notfalls für
                jede einzelne Rechnung, zur Seite.
              </p>
              <p className="text-justify">
                Bitte kontaktieren Sie bei Bedarf den Erstattungsservice von BFS
                direkt. Bei Anpassungsbedarf wendet sich BFS daraufhin direkt an
                uns und formuliert Ihnen nach Rücksprache mit uns eine
                rechtssichere und starke Stellungnahme mit diversen Urteilen
                und, falls notwendig, detaillierteren Begründungen. Dieses
                Dokument legen Sie Ihrer kostenerstattenden Stelle vor.
              </p>
              <p className="text-justify">
                Dennoch weigern sich viele Beihilfestellen oder einzelne private
                Krankenversicherungen weiterhin, die Differenz zu erstatten. Da
                wir von der Satzsteigerung oft Gebrauch machen, stellen Sie sich
                bitte schon vorab auf einen gewissen selbst zu tragenden Anteil
                ein.
              </p>
            </div>
            <img
              src={redhead}
              className="mt-20"
              style={{ borderRadius: "2rem" }}
            />
            <button
              onClick={() => {
                window.open(
                  "https://meinebfs.de/patienten/erstattungsservice/",
                  "_blank"
                );
              }}
              className="btn btn-primary mx-auto mt-30 d-block"
            >
              Hier geht es zum BFS Erstattungsservice
            </button>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
