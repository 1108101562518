import React from "react";
import { Link } from "react-router-dom";

// Elements
// import FeatureSection3 from "../elements/feature-section3";
// import TeamSection from "../elements/team";
// import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

import service1 from "../../images/services/services1.jpg";
import service2 from "../../images/services/services2.jpg";
import service3 from "../../images/services/services3.jpg";
import service4 from "../../images/services/services4.jpg";
import service5 from "../../images/services/services5.jpg";
import service6 from "../../images/services/services6.jpg";
import service7 from "../../images/services/services7.jpg";
import service8 from "../../images/services/services8.jpg";
import service9 from "../../images/services/services9.jpg";
import colors from "../../images/services/color-variants.png";
import PageTitle from "../elements/PageTitle";

const Services = () => {
  const variants = [
    {
      heading: "Elastisch offener Aktivator",
      text: (
        <React.Fragment>
          Der elastisch offene Aktivator nach Klammt ("EOA") ist ein sogenanntes
          funktionskieferorthopädisches Gerät ("FKO"), zur Korrektur der
          Frontzähne, von Kreuzbissen sowie einer Unterkiefer-Rücklage dient.
          <br />
          <br />
          FKO-Geräte wirken gleichzeitig im Ober- und im Unterkiefer und
          trainieren zusätzlich die Muskulatur so, dass Atmung, Zungenfunktion
          und Lippenschluss verbessert werden.
          <br />
          <br />
          Weil die Zahnspange innen Platz für die Zunge lässt, wird sie
          vergleichsweise zu anderen, vergleichbaren Spangen, gut toleriert und
          damit getragen.
          <br />
          <br />
          Der EOA wird stets außerhalb der Schule und Mahlzeiten getragen und
          darf sonst nicht herausgenommen werden.
        </React.Fragment>
      ),
      icon: service1,
    },
    {
      heading: "Twinblock",
      text: (
        <React.Fragment>
          Ein weiteres FKO-Gerät zur Behandlung einer Unterkiefer-Rücklage ist
          der Twinblock, der den Unterkiefer weiter nach vorne beißen lässt.
          <br />
          <br />
          Das Wachstum des Unterkiefers wird darüber hinaus angeregt und die
          Rücklage wird so in zweierlei Hinsicht behandelt.
          <br />
          <br />
          Man kann in die Zahnspangen auch Dehnschrauben einbauen lassen, um zu
          schmale Kiefer gleichzeitig zu dehnen.
          <br />
          <br />
          Weil die Zahnspange innen Platz für die Zunge lässt, wird sie
          vergleichsweise zu anderen, vergleichbaren Spangen, gut toleriert.
          <br />
          <br /> Der Twinblock wird durchgehend, also auch in der Schule
          getragen und darf sonst, außer bei Mahlzeiten und zur Zahnpflege,
          nicht herausgenommen werden.
        </React.Fragment>
      ),

      icon: service2,
    },
    {
      heading: "Fränkel III",
      text: (
        <React.Fragment>
          Auch der Fränkel III, benannt nach Professor Rolf Fränkel, ist ein
          FKO-Gerät.
          <br />
          <br />
          Er wird allen voran zur Behandlung von Unterkiefer-Vorlagen genutzt
          und regt den Oberkiefer und das Mittelgesicht zum Wachstum an.
          <br />
          <br />
          Der Fränkel III gilt als unverzichtbare Zahnspange bei dieser
          Kieferfehlstellung. Die einzig sinnvollen Alternativen müssten von
          außen befestigt werden.
          <br />
          <br />
          Diese Spange wird ebenfalls stets außerhalb der Schule und Mahlzeiten
          getragen und darf sonst nicht herausgenommen werden.
          <br />
          <br />
          Die Behandlungsdauer ist hier meist länger als bei anderen
          Zahnspangen, da ein ausgeprägtes Unterkiefer-Wachstum schwierig in den
          Griff zu bekommen ist.
          <br />
          <br />
          Doch es lohnt sich, dran zu bleiben: Oftmals kann dadurch eine spätere
          Operation vermieden werden.
        </React.Fragment>
      ),
      icon: service3,
    },
    {
      heading: "Aktive Platte",
      text: (
        <React.Fragment>
          Aktive Platten nutzen wir vor allem bei zu schmalen Kiefern.
          <br />
          <br />
          Durch das Einbauen einer Dehnschraube, die mehrmals wöchentlich durch
          Drehen aktiviert wird, können wir so sanft und sicher den Kiefer
          dehnen.
          <br />
          <br />
          Auch leichte Frontzahnfehlstände und Kreuzbisse kann man so relativ
          unkompliziert und elegant lösen.
          <br />
          <br />
          Die aktive Platte wird durchgehend, also auch in der Schule getragen
          und darf sonst, außer bei Mahlzeiten und zur Zahnpflege, nicht
          herausgenommen werden.
        </React.Fragment>
      ),
      icon: service4,
    },
    {
      heading: "herausnehmbarer Lückenhalter",
      text: (
        <React.Fragment>
          Manchmal kommt es dazu, dass Milchzähne vorzeitig entfernt werden
          müssen.
          <br />
          <br />
          Ob Karies, Trauma oder sonst etwas, weswegen die hauszahnärztliche
          Praxis dazu entscheiden musste, den Zahn oder die Zähne zu entfernen:
          <br />
          <br />
          Es ist ungemein wichtig, den Platz zu halten, da die anderen Zähne
          sonst in die Lücke wandern würden und so das korrekte Durchbrechen der
          bleibenden Zähne verhindern würde.
          <br />
          <br />
          Der Lückenhalter wird normalerweise nur nachts getragen.
          <br />
          <br />
          Sobald die bleibenden Zähne durchbrechen, für die wir die Lücke
          freigehalten haben, wird der Lückenhalter nicht mehr getragen.{" "}
        </React.Fragment>
      ),
      icon: service5,
    },
    {
      heading: "festsitzender Lückenhalter",
      text: (
        <React.Fragment>
          Kinder tragen ungern Zahnspangen. Verständlich, schließlich ist oft
          die Sprache etwas eingeschränkt, man hat einen Fremdkörper im Mund,
          sabbert etwas am Anfang. Das kann zur Strapaze bei den Elternteilen
          führen.
          <br />
          <br />
          Als Alternative zum herausnehmbaren Platzhalter bieten wir Ihnen einen
          festsitzenden an. Dieser ist sehr unauffällig, stört kaum, muss jedoch
          rechtzeitig herausgenommen werden, sobald die bleibenden Zähne
          durchbrechen möchten.
          <br />
          <br />
          Die festsitzende Variante ist leider keine Leistung der gesetzlichen
          Krankenversicherung.
        </React.Fragment>
      ),
      icon: service6,
    },
    {
      heading: "Headgear",
      text: (
        <React.Fragment>
          Das Headgear ist eine altbewährte und gut funktionierende Zahnspange
          zum Nach-Hinten-Drücken der großen Oberkiefer-Backenzähne.
          <br />
          <br />
          Doch weil die Zahnspange von außen im Gesicht befestigt werden muss,
          ist es (zurecht) der Albtraum eines jeden Kindes und der Elternteile.
          <br />
          <br />
          Leider müssen wir immer wieder beobachten, dass diese Kassenleistung
          zu Hänseleien seitens der Mitschüler*innen führt. Außerdem verursacht
          das Headgear aufgrund der starken Kräfte oftmals starke Schmerzen,
          gerade in den ersten Tagen des Tragens.
          <br />
          <br />
          Das Headgear muss im Idealfall durchgängig getragen werden, also auch
          in der Schulzeit. Nur bei leichteren Bewegungen kann ggf. auf das
          Tragen am Vormittag verzichtet werden.
          <br />
          <br />
          Nichtsdestotrotz raten wir aus psychologischen Gründen strikt von
          dieser Zahnspange ab, auch wenn die innenliegende Alternative nicht
          günstig ist.{" "}
        </React.Fragment>
      ),
      icon: service7,
    },
    {
      heading: "Distaljet",
      text: (
        <React.Fragment>
          Der Distaljet ist unsere innenliegende Alternative zum außen
          befestigten Headgear. Auch diese Zahnspange funktioniert sehr
          zuverlässig zum Nach-Hinten-Drücken der großen Oberkiefer-Backenzähne.
          <br />
          <br />
          Der Distaljet ist kaum sichtbar und muss auch nicht aktiv getragen
          werden, weil sie fest auf die Zähne zementiert wird.
          <br />
          <br />
          Das Bewegen der Zähne ist zwar weniger schmerzhaft als mit dem
          Headgear, doch wird man auch hier einen ordentlichen Druck verspüren.
          <br />
          <br />
          Der Distaljet ist keine Leistung der gesetzlichen Krankenversicherung.
          Falls jedoch ein Headgear von Nöten sein sollte, raten wir aus
          psychologischen Gründen dringend allen Elternteilen zum Distaljet als
          sogenannte außervertragliche Leistung.{" "}
        </React.Fragment>
      ),
      icon: service8,
    },
    {
      heading: "Gaumennaht-Erweiterung",
      text: (
        <React.Fragment>
          Manchmal ist der Oberkiefer so schmal, dass ein starker Kreuzbiss oder
          Scherenbiss, manchmal sogar ein seitlich offener Biss besteht, dass
          wir die knöcherne Gaumennaht in der Mitte des Oberkiefers erweitern
          müssen.
          <br />
          <br />
          Wenn das Knochenwachstum schon so weit fortgeschritten ist, dass wir
          keine langsame Dehnung des Oberkiefers mit einer herausnehmbaren
          Dehnplatte durchführen können, setzen wir eine Gaumennahtapparatur
          ("GNE") ein.
          <br />
          <br />
          Hierbei nutzen wir die "Rapid Palate Expansion"-Methode, um
          schnellstmöglich ans Ziel zu kommen. Nach dem Einzementieren der
          Zahnspange dreht man drei Mal täglich an der eingebauten Schraube, um
          den Oberkiefer zu dehnen.
          <br />
          <br />
          Nach Erreichen des Dehnziels, verschließen wir die Schraube mit
          Kunststoff, die GNE-Apparatur verbleibt dann für 3-6 Monate, damit der
          Knochen verwachsen kann.
          <br />
          <br />
          Im Anschluss setzen wir die Brackets und zusätzlich einen Bügel, der
          die großen Backenzähne beidseitig zur Stabilisierung verbindet, ein.{" "}
        </React.Fragment>
      ),
      icon: service9,
    },
  ];

  const renderService = (service, index) => {
    return (
      <div key={index} className="col-lg-4 col-md-6 mb-30">
        <div className="feature-container feature-bx2 feature1">
          <div className="feature-box-2 mb-20">
            <img src={service.icon} alt="" />
          </div>
          <div className="icon-content">
            <h3 className="ttr-title">
              <h3 className="ttr-title">{service.heading}</h3>
            </h3>
            <p className="feature-box-2__text">{service.text}</p>
            {service.link && (
              <Link to="/service-detail" className="btn btn-primary light">
                {service.link}
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageTitle
        title="Zahnspangen"
        description="Bei kleinen Kindern nutzt man in der Kassen-Kieferorthopädie
                oftmals herausnehmbare Zahnspangen, um die gröbsten Zahn- und
                Kieferfehlstellungen zu beseitigen sowie für eine optimale
                Muskelfunktion zu sorgen. So beeinflusst man vor allem das
                Kieferwachstum günstig und sorgt für eine optimale
                Muskelfunktion sowie maximale Langezeitstabilität."
        cannonical="zahnspangen"
      />
      <div className="page-content services-page bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Zahnspangen</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Zahnspangen
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-area section-sp1">
          <div className="container">
            <div>
              <h2>Zahnspangen</h2>
              <p>
                Bei kleinen Kindern nutzt man in der Kassen-Kieferorthopädie
                oftmals herausnehmbare Zahnspangen, um die gröbsten Zahn- und
                Kieferfehlstellungen zu beseitigen sowie für eine optimale
                Muskelfunktion zu sorgen. So beeinflusst man vor allem das
                Kieferwachstum günstig und sorgt für eine optimale
                Muskelfunktion sowie maximale Langezeitstabilität.
                <br />
                <br />
                Die Zahnspangen werden so lange wie möglich getragen und
                teilweise selbst mit eingestellt. Nur im individuellen Fall
                erlauben wir das Weglassen während der Unterrichtszeit. Dank der
                herausnehmbaren Zahnspangen kann man spätere Behandlungen
                erleichtern und manchmal sogar verhindern.
                <br />
                <br />
                Hier eine Auswahl der gängigsten herausnehmbaren Zahnspangen
                sowie ein paar spezielle, festsitzende Varianten:
              </p>
            </div>
            <div className="row">
              {/* {services.map((v, i) => renderService(v, `service-{i}`))} */}

              {/* <div className="col-lg-12 col-md-18 mb-30">
                <h3>Varianten</h3>
                <p>
                  Oben haben wir die grundlegenden Arten beschrieben, doch
                  innerhalb der herausnehmbaren und festsitzenden Zahnspangen
                  gibt es diverse Unterarten, je nachdem, was gemacht werden
                  muss.
                </p>
              </div> */}

              {variants.map((v, i) => renderService(v, `variant-{i}`))}
            </div>
          </div>
        </section>
        <ColorSelection />

        {/* <FeatureSection3 /> */}

        {/* <TeamSection /> */}

        {/* <LatestNewsSection /> */}
      </div>
    </>
  );
};

const ColorSelection = () => {
  return (
    <div className="services-page__color-selection container">
      <h3>Unsere Farbauswahl</h3>
      <h4>So individuell wie das Kind selbst.</h4>
      <p>
        Viele Kinder freuen sich wie Bolle, wenn sie eine Zahnspange bekommen
        sollen. Um die Vorfreude größer zu machen und damit die Kinder motiviert
        und gern ihre Zahnspangen tragen, darf sich jedes Kind die Farben der
        herausnehmbaren Zahnspange selbst aussuchen.
        <br />
        <br />
        Man kann nicht nur mehrere Farben aussuchen, sondern auch verschiedene
        Glitzer oder auf Nachfrage z. B. auch Motive wie einen Fußball
        einarbeiten lassen.
        <br />
        <br />
      </p>
      <img className="mx-auto d-block mt-20 " src={colors} />
      {/* <button
        onClick={() => {
          console.log("download pdf");
        }}
        className="btn btn-primary light"
      >
        Farbauswahl für herausnehmbare Zahnspangen
      </button> */}
    </div>
  );
};

export default Services;

// const services = [
//   {
//     heading: "Herausnehmbar",
//     text: `Bei kleinen Kindern nutzt man in der
// Kassen-Kieferorthopädie oftmals herausnehmbare
// Zahnspangen, um die gröbsten Zahn- und
// Kieferfehlstellungen zu beseitigen sowie für eine optimale
// Muskelfunktion zu sorgen. So beeinflusst man vor allem das
// Kieferwachstum günstig und sorgt für eine optimale
// Muskelfunktion sowie maximale Langezeitstabilität. Die
// Zahnspangen werden so lange wie möglich getragen und
// teilweise selbst mit eingestellt. Nur im individuellen
// Fall erlauben wir das Weglassen während der
// Unterrichtszeit. Dank der herausnehmbaren Zahnspangen kann
// man spätere Behandlungen erleichtern und manchmal sogar
// verhindern.`,
//     icon: (
//       <svg
//         enableBackground="new 0 0 512 512"
//         height="80"
//         viewBox="0 0 512 512"
//         width="80"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="m218.578 512c-29.085 0-52.748-23.656-52.748-52.734v-102.154c0-5.522 4.477-10 10-10s10 4.478 10 10v102.153c0 18.05 14.69 32.734 32.748 32.734s32.748-14.685 32.748-32.734v-116.18c0-20.084 16.343-36.423 36.432-36.423s36.432 16.339 36.432 36.423v59.66c0 24.042 19.567 43.602 43.619 43.602s43.619-19.56 43.619-43.602v-170.256c0-5.522 4.477-10 10-10s10 4.478 10 10v170.256c0 35.07-28.54 63.602-63.619 63.602s-63.619-28.531-63.619-63.602v-59.66c0-9.056-7.371-16.423-16.432-16.423s-16.432 7.367-16.432 16.423v116.181c0 29.078-23.663 52.734-52.748 52.734z"
//           fill="#020288"
//         />
//         <ellipse
//           cx="175.83"
//           cy="336.898"
//           fill="#b2f0fb"
//           rx="30.275"
//           ry="30.265"
//         />
//         <path
//           d="m317.745 103.718h-10.12v-78.989c0-5.522-4.477-10-10-10h-55.743v-4.729c0-5.522-4.477-10-10-10s-10 4.478-10 10v29.456c0 5.522 4.477 10 10 10s10-4.478 10-10v-4.728h45.743v68.989h-10.119c-5.523 0-10 4.478-10 10v47.531c0 50.532-41.126 91.644-91.677 91.644-50.55 0-91.676-41.111-91.676-91.644v-47.531c0-5.522-4.477-10-10-10h-10.119v-68.988h45.743v4.728c0 5.522 4.477 10 10 10s10-4.478 10-10v-29.457c0-5.522-4.477-10-10-10s-10 4.478-10 10v4.729h-55.743c-5.523 0-10 4.478-10 10v78.989h-10.119c-5.523 0-10 4.478-10 10v47.531c0 83.741 68.149 151.869 151.915 151.869s151.915-68.128 151.915-151.869v-47.531c0-5.523-4.477-10-10-10zm-10 57.531c0 72.713-59.177 131.869-131.915 131.869s-131.915-59.156-131.915-131.869v-37.531h20.238v37.531c0 61.561 50.098 111.644 111.676 111.644s111.677-50.083 111.677-111.644v-37.531h20.239z"
//           fill="#020288"
//         />
//         <ellipse
//           cx="421.426"
//           cy="170.539"
//           fill="#b2f0fb"
//           rx="66.659"
//           ry="66.637"
//         />
//         <path
//           d="m421.427 202.534c-17.646 0-32.001-14.353-32.001-31.995s14.356-31.994 32.001-31.994 32.001 14.353 32.001 31.994c0 17.643-14.356 31.995-32.001 31.995zm0-43.989c-6.618 0-12.001 5.381-12.001 11.994 0 6.614 5.384 11.995 12.001 11.995s12.001-5.381 12.001-11.995c0-6.613-5.384-11.994-12.001-11.994z"
//           fill="#020288"
//         />
//       </svg>
//     ),
//     link: "Mehr Infos zur Kassenbehandlung",
//   },

//   {
//     heading: "Festsitzend",
//     text: `Bei jugendlichen Kassenpatient*innen sind Brackets oftmals
// das Mittel der Wahl. Einserseits können mit diesen fast
// alle Probleme ausbehandelt werden, andererseits sind bei
// Minderjährigen diese Behandlungen bei bestimmten
// Fehlstellungen Leistungen der gesetzlichen Krankenkasse.
// Auch andere, festsitzende Hilfsmittel werden je nach
// Ausprägung der Zahn- und Kieferfehlstellung genutzt.
// Zusätzlich zur Kassenleistung bieten wir Ihnen stets
// weitere sinnvolle Leistungen wie flexible
// Nickel-Titan-Bögen, Mini-Brackets, Versiegelungen an und
// bessere Retention an. Auch durchsichtige Brackets und
// ästhetische Bögen sind im Rahmen außervertraglicher
// Leistungen selbstverständlich machbar.`,
//     icon: (
//       <svg
//         enableBackground="new 0 0 512 512"
//         height="80"
//         viewBox="0 0 512 512"
//         width="80"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <g fill="#020288">
//           <path d="m311.734 208.706h-25.074v-25.083c0-5.522-4.478-10-10-10h-41.32c-5.523 0-10 4.478-10 10v25.083h-25.074c-5.523 0-10 4.478-10 10v41.33c0 5.522 4.477 10 10 10h25.074v25.082c0 5.522 4.477 10 10 10h41.32c5.522 0 10-4.478 10-10v-25.082h25.074c5.522 0 10-4.478 10-10v-41.33c0-5.522-4.477-10-10-10zm-10 41.33h-25.074c-5.522 0-10 4.478-10 10v25.082h-21.32v-25.082c0-5.522-4.477-10-10-10h-25.074v-21.33h25.074c5.523 0 10-4.478 10-10v-25.083h21.32v25.083c0 5.522 4.478 10 10 10h25.074z" />
//           <path d="m330.566 120.217v-51.05c0-5.522-4.478-10-10-10h-14.759v-49.167c0-5.522-4.478-10-10-10h-79.616c-5.523 0-10 4.478-10 10v49.167h-14.758c-5.523 0-10 4.478-10 10v51.049c-37.43 23.089-62.429 64.475-62.429 111.589v270.195c0 5.522 4.477 10 10 10h253.992c5.522 0 10-4.478 10-10v-270.194c0-47.115-24.999-88.501-62.43-111.589zm-104.374-100.217h19.808v2.559c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10v-2.559h19.808v39.167h-59.616zm-24.759 59.167h109.133v30.965c-15.03-6.023-31.427-9.338-48.583-9.338h-11.967c-17.156 0-33.552 3.315-48.583 9.338zm171.563 412.833h-233.992v-260.194c0-61.212 49.8-111.012 111.012-111.012h11.967c61.213 0 111.013 49.8 111.013 111.012z" />
//           <path
//             d="m181.465 350.096h149.069v102.32h-149.069z"
//             fill="#a4fcc4"
//           />
//         </g>
//       </svg>
//     ),
//     link: "Mehr Infos zur Kassenbehandlung",
//   },
//   {
//     heading: "Aligner",
//     text: `Die modernste Methode einer kieferorthopädischen
// Behandlung stellt die Aligner-Therapie mit durchsichtigen,
// unauffälligen Schienen dar. Als spezialisierter und
// Premier-zertifizierter Invisalign-Anwender ist es Herrn
// d-r Keyhani möglich, alle Fehlstellungen mit dem
// einzigartigen System vom Pionier der Aligner, Invisalign
// zu korrigieren. Auch Kinder und Jugendliche können
// mithilfe des Invisalign-Systems behandelt werden. Diese
// Art von Behandlung wird von den gesetzlichen
// Krankenversicherungen leider nicht getragen. Aufgrund der
// zahlreichen Vorteile dieser Methode, behandeln wir
// Privatpatient*innen und Selbstzahler*innen grundsätzlich
// mit Invisalign-Alignern.`,
//     icon: (
//       <svg
//         enableBackground="new 0 0 512 512"
//         height="80"
//         viewBox="0 0 512 512"
//         width="80"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="m318.39 278.299h-39.263v-39.262h-46.254v39.262h-39.263v46.255h39.263v39.263h46.254v-39.263h39.263z"
//           fill="#ffbdbc"
//         />
//         <g fill="#020288">
//           <path d="m256 164.444c-75.533 0-136.983 61.45-136.983 136.982s61.45 136.983 136.983 136.983 136.983-61.45 136.983-136.982-61.45-136.983-136.983-136.983zm0 253.965c-64.504 0-116.983-52.479-116.983-116.982s52.479-116.983 116.983-116.983 116.983 52.479 116.983 116.982-52.479 116.983-116.983 116.983z" />
//           <path d="m470.541 112.15h-100.492v-50.962c0-20.205-16.429-36.643-36.623-36.643h-154.853c-20.194 0-36.623 16.438-36.623 36.643v50.963h-100.491c-22.86-.001-41.459 18.598-41.459 41.458v292.387c0 22.86 18.599 41.459 41.459 41.459h429.082c22.86 0 41.459-18.599 41.459-41.459v-292.387c0-22.86-18.599-41.459-41.459-41.459zm-34.541 20v36.68h-50.511v-36.68zm-274.049-70.962c0-9.177 7.457-16.643 16.623-16.643h154.854c9.166 0 16.623 7.466 16.623 16.643v50.963h-24.765v-32.806c0-5.522-4.477-10-10-10h-118.57c-5.523 0-10 4.478-10 10v32.806h-24.765zm44.765 50.962v-22.805h98.568v22.806h-98.568zm-80.205 20v36.68h-50.511v-36.68zm365.489 313.846c0 11.833-9.626 21.459-21.459 21.459h-429.082c-11.833 0-21.459-9.626-21.459-21.459v-292.387c0-11.833 9.626-21.459 21.459-21.459h14.541v46.68c0 5.522 4.477 10 10 10h70.511c5.523 0 10-4.478 10-10v-46.68h218.979v46.68c0 5.522 4.477 10 10 10h70.51c5.523 0 10-4.478 10-10v-46.68h14.541c11.833 0 21.459 9.626 21.459 21.459z" />
//         </g>
//       </svg>
//     ),
//     link: "Mehr Infos zur Privatbehandlung",
//   },
// ];
