import React from "react";
import Banner from "../elements/Banner";

import bruxismus from "../../images/private-treatment/bruxismus.jpg";
import fluoridierung from "../../images/fluoridierung.jpg";
import zahnreinigung from "../../images/zahnreinigung.jpg";
import ernaehrung from "../../images/ernaehrung.jpg";
import handlungsempfehlung from "../../images/handlungsempfehlung.jpg";
import BookingButton from "../elements/BookingButton";
import useWindowDimensions from "../../useWindowDimensions";
import PageTitle from "../elements/PageTitle";

export default function Prophylaxis() {
  const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="Prophylaxe"
        description="Prävention"
        canonical="prophylaxe"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Prophylaxe" />

          <div className="container mt-50">
            {/* <h3 className="text-center mt-30">Prävention</h3> */}
            <div className="diagnostic-text-with-image">
              <div>
                <h4 className="mt-30 text-secondary">Zahnreinigung</h4>
                <p className="text-justify mt-20">
                  Gesunde Milchzähne sind entscheidend für gesunde, bleibende
                  Zähne. Unsere Vorsorge ergänzt das tägliche Zähneputzen, da es
                  immer Stellen gibt, die Zahnbürste und Zahnseide nicht
                  erreichen. Diese hartnäckigen Beläge machen wir durch Anfärben
                  für Ihr Kind sichtbar, damit es versteht, was es sonst
                  übersehen würde.
                </p>

                <p className="text-justify">
                  Unsere professionelle Zahnreinigung (PZR) erfolgt mit sanften,
                  kindgerechten Reinigungsmitteln. Die Politur mittels
                  Pulverstrahlgerät reinigt und glättet die Zahnoberfläche,
                  sodass Beläge weniger haften bleiben. Die Behandlung ist für
                  Ihr Kind schmerzfrei! Ein guter Fluoridlack wird anschließend
                  aufgetragen, um den Schutz der Zähne zu verbessern.
                </p>
                <p className="text-justify">
                  Ein weiterer Vorteil: Ihr Kind gewöhnt sich schonend an
                  zahnärztliche Behandlungen, was zukünftige Eingriffe
                  erleichtert. Zur Verbesserung der Mundhygiene zuhause führen
                  wir Übungen zum richtigen Zähneputzen und zur Verwendung von
                  Zahnseide durch, wobei wir je nach Alter und Bedarf
                  unterschiedliche Schwerpunkte setzen.
                </p>
              </div>

              <img
                style={{
                  objectFit: "cover",
                  objectPosition: "left",
                }}
                src={zahnreinigung}
              />
            </div>

            <div className="diagnostic-text-with-image--reverse mt-60">
              <div>
                <h4 className="mt-30 text-secondary">Fluoridierung</h4>
                <p className="text-justify mt-20">
                  Fluoride sind natürliche Mineralien, die in Lebensmitteln und
                  Trinkwasser vorkommen. Jedoch reicht die tägliche Aufnahme
                  nicht aus, um effektiv vor Karies zu schützen. Deshalb werden
                  Fluoridlacke verwendet, um beginnende Karies zu stoppen oder
                  ihr vorzubeugen.
                </p>

                <p className="text-justify">
                  Zahnärzte empfehlen generell den regelmäßigen Gebrauch
                  fluoridhaltiger Zahnpflegeprodukte, insbesondere Zahnpasta, um
                  die Zähne zu stärken und vor Zucker- und Säureangriffen zu
                  schützen. Bei Kindern bis zum 6. Lebensjahr ist jedoch die
                  richtige Dosierung und Anwendung entscheidend, um eine
                  Überfluoridierung zu vermeiden. Aus diesem Grund hat die
                  Bundeszahnärztekammer (BZK) offizielle Empfehlungen für
                  Kinderzahnpasten herausgegeben.
                </p>
              </div>
              {width >= 992 && <img src={fluoridierung} />}
            </div>
            <img
              src={handlungsempfehlung}
              className="mt-50"
              style={{ borderRadius: "2rem" }}
            />
            <div className="diagnostic-text-with-image mt-60">
              <div>
                <h4 className="mt-30 text-secondary">Bruxismus</h4>
                <p className="text-justify mt-20">
                  Bruxismus ist das unbewusste, meist nächtliche, aber auch
                  tagsüber ausgeführte Zähneknirschen oder Aufeinanderpressen
                  der Zähne.
                  <br />
                  <br />
                  Dies führt regelmäßig zu starken Muskelverspannungen im
                  Kiefer, Nacken und Rücken, nicht selten auch zu akuten
                  Schmerzzuständen.
                  <br />
                  <br />
                  Bruxismus kann schon früh im Milchgebiss auftreten und ist
                  biologisch oder durch unnatürliche Faktoren bedingt.
                  <br />
                  <br />
                  Wir untersuchen die Ursachen und fertigen, falls notwendig,
                  einen Schutz für die Zähne an, um Risse und
                  Überempfindlichkeit zu verhindern. Denn eine Schiene kann das
                  Knirschen deutlich reduzieren.
                </p>
              </div>
              <img src={bruxismus} />
            </div>

            <div className="diagnostic-text-with-image--reverse mt-60">
              <div>
                <h4 className="mt-30 text-secondary">Gesunde Ernährung</h4>
                <p className="text-justify mt-20">
                  Gesunde Ernährung und Trinkgewohnheiten sind entscheidend für
                  die Zahngesundheit. Zucker und Säure können den Zähnen
                  schaden, daher ist nicht nur die Menge, sondern auch die
                  Häufigkeit des Zuckerkonsums wichtig. Eine ausgewogene
                  Ernährung, reich an Vitaminen, Mineralien und Spurenelementen,
                  ist empfehlenswert.
                </p>
                <p className="text-justify">
                  Regelmäßige Mahlzeiten mit 3 Haupt- und 2 Zwischenmahlzeiten
                  sowie Wasser als Durstlöscher sind ideal. Gesund für
                  Kinderzähne sind Lebensmittel wie Brot, Nudeln, Obst, Gemüse
                  und falls Tier(produkt)e verzehrt werden, auch Milchprodukte,
                  mageres Fleisch, Fisch und Eier.
                </p>
                <p className="text-justify">
                  Hingegen sollten süße Zwischenmahlzeiten, weiche und klebrige
                  Kinderprodukte, fertige Produkte mit Zucker sowie zucker- und
                  säurehaltige Getränke vermieden werden. Produkte mit
                  "verstecktem Zucker" können ebenso kariesfördernd sein.
                </p>
                <p className="text-justify">
                  Geben Sie Ihrem Kind gesundes Obst, Gemüse oder festes Brot
                  zum Kauen, um die Kaumuskulatur zu trainieren und die
                  Speichelproduktion anzuregen. Speichel spült die Zähne und
                  härtet sie, wodurch zahnschädigende Säuren neutralisiert
                  werden.
                </p>
                <p className="text-justify">
                  Für Kinder, die gerne Süßes essen, können Produkte mit dem
                  weißen Zahnmännchen-Logo eine zahnfreundliche Alternative
                  sein. Diese Produkte sind speziell auf die Zahngesundheit
                  ausgerichtet.
                </p>
              </div>
              <img
                style={{
                  objectFit: "cover",
                }}
                src={ernaehrung}
              />
            </div>
          </div>
          <BookingButton
            classes="btn-primary mt-60 d-block mx-auto"
            text="Hier geht es zur Terminbuchung"
          />
        </section>
      </div>
    </React.Fragment>
  );
}
