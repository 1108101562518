import React from "react";
import Banner from "../elements/Banner";
import PageTitle from "../elements/PageTitle";

export default function Impressum() {
  window.addEventListener(
    "load",
    function (e) {
      const script = document.createElement("script");
      script.src =
        "https://consent.cookiebot.com/21eb6ebe-3372-432d-9e40-9d36eac83d70/cd.js";
      script.setAttribute("data-culture", "de");
      document.body.appendChild(script);
    },
    false
  );

  return (
    <React.Fragment>
      <PageTitle
        title="Impressum und Datenschutz"
        description="Impressum und Datenschutz"
        canonical="impressum"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Impressum und Datenschutz" />
          <div className="container">
            <h2 className="text-center">Impressum</h2>
            <h5>Angaben gem. § 5 TMG</h5>
            <p>
              Verantwortlich für die Inhalte und Impressum von
              www.fliegender-zahn.de:
            </p>
            <br />
            <h5>Betreiber und Kontakt:</h5>
            <p>
              Christine Zarro und Milan Keyhani GbR
              <br />
              Jasperallee 36
              <br />
              38102 Braunschweig
              <br />
              Telefon: 0531 2245 8888
            </p>
            <h5>Berufsbezeichnung:</h5>
            <p>Zahnärzte, verliehen in der Bundesrepublik Deutschland.</p>
            <h5>Zuständige Kammer / Aufsichtsbehörde</h5>
            <p>
              Kassenzahnärztliche Vereinigung Niedersachsen (KZVN)
              <br />
              Zeißstr. 11
              <br />
              30519 Hannover
              <br />
              Tel: 0511 84050
              <br />
              www.kzvn.de
            </p>

            <h5 className="mt-20">Haftung für Inhalte</h5>
            <p>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <h5 className="mt-20">Haftung für Links</h5>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <h5 className="mt-20">Urheberrecht</h5>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>

            <h2 className="text-center mt-50">Datenschutz</h2>

            <p>
              {" "}
              Nachfolgend erhalten Sie Informationen über die Erhebung
              personenbezogener Daten bei Nutzung unserer Internetseite. Zu den
              personenbezogene Daten gehören alle Daten, die auf Sie persönlich
              beziehbar sind (z.B. Name, Adresse, E-Mail-Adressen,
              Nutzerverhalten, IP-Adresse).
            </p>
            <br />

            <p>
              <strong>
                1. Wer ist in unserer Praxis für den Datenschutz verantwortlich?
              </strong>{" "}
              In unserer Praxis ist für den Datenschutz verantwortlich und steht
              Ihnen bei Fragen zur Verfügung:
            </p>
            <br />
            <p>d-r Milan Keyhani, M. Sc., B. Sc., M. Sc.</p>
            <p>
              Rechtsform der Praxis: Berufsausübungsgemeinschaft als
              Gesellschaft bürgerlichen Rechts
            </p>

            <p>
              Praxisadresse:
              <br />
              Jasperallee 36
              <br />
              38102 Braunschweig
              <br />
              Telefon: 0531 2245 8888
              <br />
              E-Mail: praxis@fliegender-zahn.de
            </p>

            <p>
              <strong>
                2. Über wen erheben wir welche personenbezogenen Daten?
              </strong>
              <br />
              Wir erheben, speichern, nutzen, übermitteln oder löschen über
              folgende Personen personenbezogene Daten:
              <ul>
                <li className="ml-20">
                  ·Interessenten unserer Internetseite und Patienten unserer
                  Praxis, die natürliche Personen sind
                </li>
                <li className="ml-20">
                  ·Allen anderen natürlichen Personen, die in Kontakt mit
                  unserer Praxis stehen (z.B. Bevollmächtigte von Patienten,
                  Erziehungsberechtigte von Patienten, Mitarbeiter juristischer
                  Personen, Besucher unserer Internetseite) Personenbezogene
                  Daten von Ihnen werden von uns erhoben, wenn Sie mit uns z.B.
                  per E-Mail oder Telefon in Kontakt treten und einen
                  Behandlungstermin ausmachen wollen. Folgende persönliche Daten
                  verarbeiten wir:
                </li>
                <li className="ml-20">
                  ·Persönliche Angaben (z.B. Vor- und Nachnamen, Adresse,
                  Geburtsdatum und -ort, E-Mail-Adresse, Telefonnummer,
                  Versicherungsstatus);
                </li>
                <li className="ml-20">
                  ·Daten zu Ihrem Online-Verhalten und -präferenzen (z.B.
                  IP-Adressen, eindeutige Zuordnungsmerkmale mobiler Endgeräte,
                  Daten zu Ihren Besuchen auf unserer Internetseite, Endgeräte,
                  mit denen Sie unsere Internetseite besucht haben) Angaben zu
                  Kindern erheben wir nur dann, wenn diese durch die
                  Erziehungsberechtigten in unserer Praxis als Patient
                  vorgestellt werden.
                </li>
              </ul>
            </p>

            <p>
              <strong>
                3. Wer erhält ggf. Ihre personenbezogenen Daten übermittelt?
              </strong>
              x
              <br />
              Falls einzelne Funktionen unserer Internetseite auf beauftragte
              Dienstleister zurückgreifen, informieren wir Sie in Ziffer 5
              dieser Datenschutzerklärung im Detail über die jeweiligen
              Vorgänge. Dabei nennen wir auch die festgelegten Kriterien der
              Speicherdauer.
            </p>

            <p>
              <strong>
                4. Welchem Zweck dienen Ihre Daten und mit welcher
                Rechtfertigung dürfen wir diese nutzen?
              </strong>
              <br />
              Wenn Sie unsere Internetseite ausschließlich dazu nutzen, sich
              über unsere Praxis zu informieren, erheben wir nur die
              personenbezogenen Daten, die Ihr Browser an unseren Server
              übermittelt. Wenn Sie unsere Website betrachten möchten, erheben
              wir die folgenden Daten, die für uns technisch erforderlich sind,
              um Ihnen unsere Website anzuzeigen und die Stabilität und
              Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S.
              1 lit. f DSGVO):
              <ul>
                <li className="ml-20">IP-Adresse</li>
                <li className="ml-20">Datum und Uhrzeit der Anfrage</li>
                <li className="ml-20">
                  Zeitzonendifferenz zur Greenwich Mean Time (GMT)
                </li>
                <li className="ml-20">
                  Inhalt der Anforderung (konkrete Seite)
                </li>
                <li className="ml-20">Zugriffsstatus/HTTP-Statuscode</li>
                <li className="ml-20">jeweils übertragene Datenmenge</li>
                <li className="ml-20">
                  Website, von der die Anforderung kommt
                </li>
                <li className="ml-20">Browser</li>
                <li className="ml-20">Betriebssystem und dessen Oberfläche</li>
                <li className="ml-20">
                  Sprache und Version der Browsersoftware.
                </li>
              </ul>
              Zusätzlich zu diesen Daten werden bei Ihrer Nutzung unserer
              Internetseite auch Cookies auf Ihrem Rechner gespeichert. Bei
              Cookies handelt es sich um kleine Textdateien, die auf Ihrer
              Festplatte dem von Ihnen verwendeten Browser zugeordnet
              gespeichert werden und durch welche der Stelle, die den Cookie
              setzt (hier durch uns), bestimmte Informationen zufließen. Cookies
              können weder Programme ausführen oder noch Viren auf Ihren
              Computer übertragen. Sie dienen dazu, das Internetangebot
              insgesamt nutzerfreundlicher und effektiver zu machen. Einsatz von
              Cookies:
              <br />
              <br />
              a)Diese Website nutzt folgende Arten von Cookies, deren Umfang und
              Funktionsweise im Folgenden erläutert werden:
              <ul>
                <li className="ml-20">Transiente Cookies (dazu b)</li>
                <li className="ml-20">Persistente Cookies (dazu c).</li>
              </ul>
              b)Transiente Cookies werden automatisiert gelöscht, wenn Sie den
              Browser schließen. Dazu zählen insbesondere die Session-Cookies.
              Diese speichern eine sogenannte Session-ID, mit welcher sich
              verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
              zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden,
              wenn Sie auf unsere Website zurückkehren. Die Session-Cookies
              werden gelöscht, wenn Sie sich ausloggen oder den Browser
              schließen.
              <br />
              <br />
              c)Persistente Cookies werden automatisiert nach einer vorgegebenen
              Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
              können die Cookies in den Sicherheitseinstellungen Ihres Browsers
              jederzeit löschen.
              <br />
              <br />
              d)Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
              konfigurieren und z.B. die Annahme von Third-Party-Cookies oder
              allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie
              eventuell nicht alle Funktionen dieser Website nutzen können.
              Wollen Sie per E-Mail oder über unser Kontaktformular einen
              Behandlungstermin vereinbaren oder eine Frage an uns richten,
              werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf.
              Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihnen
              einen Behandlungstermin zuweisen oder die Anfrage beantworten zu
              können. Die in diesem Zusammenhang anfallenden Daten löschen wir,
              nachdem die Speicherung nicht mehr erforderlich ist, oder
              schränken die Verarbeitung ein, falls gesetzliche
              Aufbewahrungspflichten bestehen (Rechtsgrundlage ist Art. 6 Abs. 1
              S. 1 lit. b DSGVO).
            </p>

            <p>
              <strong>
                5. Welche Rechte haben Sie im Zusammenhang mit dem Datenschutz?
              </strong>
              <br />
              Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
              betreffenden personenbezogenen Daten:
              <ul>
                <li className="ml-20">Recht auf Auskunft,</li>
                <li className="ml-20">Recht auf Berichtigung oder Löschung,</li>
                <li className="ml-20">
                  Recht auf Einschränkung der Verarbeitung,
                </li>
                <li className="ml-20">
                  Recht auf Widerspruch gegen die Verarbeitung,
                </li>
                <li className="ml-20">Recht auf Datenübertragbarkeit.</li>
              </ul>
              Wer erhält ggf. Ihre personenbezogenen Daten übermittelt? Unter
              anderem nutzen wir den smarten Telefonassistenten der Aaron GmbH,
              um Ihre Anliegen zu verwalten und zu bearbeiten. Die
              Datenverarbeitung beruht auf einem Auftragsverarbeitungsvertrag
              nach Artikel 28 DSGVO. Die Aaron GmbH ist gesetzlich und
              vertraglich zur Geheimhaltung und zum Schutz Ihrer Daten
              verpflichtet.
            </p>
            <p>
              <strong>6. Wie können Sie sich ggf. beschweren?</strong>
              <br />
              Sie haben die Möglichkeit, sich mit einer Beschwerde im
              Zusammenhang mit der Datenverarbeitung an die für uns zuständige
              Datenschutzaufsicht zu wenden: Barbara Thiel
            </p>
            <p>
              Die Landesbeauftragte für den Datenschutz Niedersachsen
              <br />
              Prinzenstraße 5
              <br />
              30159 Hannover
              <br />
              Telefon: 0511 120-4500
              <br />
              Fax: 0511 120-4599
              <br />
              E-Mail: poststelle@lfd.niedersachsen.de
            </p>
            <h2 className="text-center mt-50">Cookie-Erklärung</h2>
            <div id="CookieDeclaration"></div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
