import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../useWindowDimensions";

// Images
import Logo from "../../images/logo-6.png";
import footerBg from "../../images/background/footer.jpg";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";
import { Context } from "../../context";

const orthoItems = [
  { id: "home", name: "Home", linkName: "home" },
  { id: "appliances", name: "Zahnspangen", linkName: "zahnspangen" },
  {
    id: "behandlungsarten",
    name: "Behandlungsarten",
    linkName: "",
    subItems: [
      {
        id: "public-treatment",
        displayName: "Kassenbehandlung",
        linkName: "kassenbehandlung",
      },
      {
        id: "privatbehandlung",
        displayName: "Privatbehandlung",
        linkName: "privatbehandlung",
      },
    ],
  },
  { id: "cmd", name: "CMD und Bruxismus", linkName: "cmd-bruxismus" },
  {
    id: "lips",
    name: "Lippenunterspritzung",
    linkName: "lippenunterspritzung",
  },
  {
    id: "finanzen",
    name: "Finanzen",
    linkName: "",
    subItems: [
      {
        id: "zusatzversicherung",
        displayName: "Zusatzversicherung",
        linkName: "zusatzversicherung",
      },
      {
        id: "ratenzahlung",
        displayName: "Ratenzahlung",
        linkName: "ratenzahlung",
      },
      {
        id: "kostenerstattung",
        displayName: "Kostenerstattung",
        linkName: "kostenerstattung",
      },
    ],
  },
  {
    id: "more",
    name: "Mehr",
    linkName: "",
    subItems: [
      { id: "faq", displayName: "FAQ", linkName: "faq" },
      { id: "kontakt", displayName: "Kontakt", linkName: "kontakt" },
      { id: "karriere", displayName: "Karriere", linkName: "karriere" },
      {
        id: "impressum-datenschutz",
        displayName: "Impressum und Datenschutz",
        linkName: "impressum-datenschutz",
      },
      {
        id: "pediatric",
        displayName: "Kinder- und Jugendzahnheilkunde",
        linkName: "home",
      },
    ],
  },
];

const pediatricItems = [
  { id: "home", name: "Home", linkName: "home" },
  { id: "prophylaxe", name: "Prophylaxe", linkName: "prophylaxe" },
  {
    id: "diagnostik",
    name: "Diagnostik",
    linkName: "diagnostik",
  },
  {
    id: "behandlungen",
    name: "Behandlungen",
    linkName: "behandlungen",
  },
  {
    id: "sedierung",
    name: "Sedierung und Narkose",
    linkName: "sedierung",
  },
  {
    id: "finanzen",
    name: "Finanzen",
    linkName: "",
    subItems: [
      {
        id: "zusatzversicherung",
        displayName: "Zusatzversicherung",
        linkName: "zusatzversicherung",
      },
      {
        id: "ratenzahlung",
        displayName: "Ratenzahlung",
        linkName: "ratenzahlung",
      },
      {
        id: "kostenerstattung",
        displayName: "Kostenerstattung",
        linkName: "kostenerstattung",
      },
    ],
  },
  {
    id: "more",
    name: "Mehr",
    linkName: "",
    subItems: [
      // { id: "faq", displayName: "FAQ", linkName: "faq" },
      { id: "kontakt", displayName: "Kontakt", linkName: "kontakt" },
      { id: "karriere", displayName: "Karriere", linkName: "karriere" },
      {
        id: "impressum-datenschutz",
        displayName: "Impressum und Datenschutz",
        linkName: "impressum-datenschutz",
      },
      { id: "ortho", displayName: "Kieferorthopädie", linkName: "home" },
    ],
  },
];

const AboutSection = () => {
  const { selectedFlow, setSelectedFlow } = useContext(Context);
  const items = selectedFlow === "ortho" ? orthoItems : pediatricItems;
  const { width } = useWindowDimensions();

  const transformItems = (items, widthThreshold) => {
    return items.reduce((acc, item) => {
      if (item.subItems) {
        const transformedSubItems = item.subItems.map((subItem) => ({
          ...subItem,
          name: subItem.displayName,
        }));
        return [...acc, ...transformedSubItems];
      } else {
        return [...acc, item];
      }
    }, []);
  };

  const transformedItems = transformItems(items, 1200);

  const sliceVal = selectedFlow === "ortho" ? 7 : 6;

  return (
    <>
      <footer
        className="footer"
        style={{ backgroundImage: "url(" + footerBg + ")" }}
      >
        <div className="footer-top">
          <div
            className="container"
            style={{
              maxWidth: "80vw",
            }}
          >
            <div
              className={`d-flex align-items-center ${
                width < 992 ? "flex-column justify-content-center" : "flex-row "
              }`}
            >
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // ...(width >= 992 || width < 600
                    //   ? { alignItems: "center" }
                    //   : {}),
                    // marginLeft: "7rem",
                    // alignItems: "center",
                  }}
                  className="widget widget_info"
                >
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src={Logo}
                        style={{
                          //  marginLeft: "-2.5rem",
                          maxWidth: "320px",
                        }}
                        alt=""
                        onClick={() => setSelectedFlow(null)}
                      />
                    </Link>
                  </div>
                  <div
                    className="ft-contact"
                    onClick={() => window.open("tel:053122458888", "_self")}
                  >
                    <div className="contact-bx">
                      <div className="icon">
                        <i className="fas fa-phone-alt"></i>
                      </div>
                      <div className="contact-number">
                        <span>Kontakt</span>
                        <h4 className="number">0531 2245 8888</h4>
                      </div>
                    </div>

                    <ul
                      className="social-media d-flex flex-row justify-content-center "
                      style={{
                        marginTop: "1.6rem",
                        // marginLeft: "3.7rem",
                      }}
                    >
                      <li style={{ marginRight: "2rem" }}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.instagram.com/smile_with_milan/"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="mailto:praxis@fliegender-zahn.de"
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-12">
                <div className="widget footer_widget ml-50">
                  <h3
                    className={`footer-title  ${
                      true ? "mt-50 text-center" : ""
                    }`}
                  >
                    Quick Links
                  </h3>
                  <div
                    className={`d-flex justify-content-center ${
                      width < 992 ? "flex-column" : "flex-row"
                      // true ? "flex-column" : "flex-row"
                    }`}
                  >
                    <ul
                      className={`
                      d-flex flex-column ${
                        width < 992 ? "align-items-center" : ""
                      }
                      `}
                    >
                      {[
                        ...(width < 768
                          ? transformedItems
                          : transformedItems.slice(0, sliceVal)),
                      ].map((item) => (
                        <li key={item.id}>
                          <Link to={`/${item.linkName}`}>
                            <span>{item.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    {width >= 768 && (
                      <ul
                        className={` d-flex flex-column  ${
                          width < 992 ? "align-items-center" : "ml-60"
                        }`}
                      >
                        {transformedItems.slice(sliceVal).map((item) => (
                          <li key={item.id}>
                            <Link
                              onClick={() => {
                                if (item.id !== "more") {
                                  if (
                                    item.id === "ortho" ||
                                    item.id === "pediatric"
                                  ) {
                                    window.scrollTo(0, 0);
                                    setSelectedFlow(item.id);
                                  }
                                }
                              }}
                              to={`/${item.linkName}`}
                            >
                              <span>{item.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
              <div className="col-12 text-center">
                <p className="copyright-text">
                  Fliegender Zahn © Urheberrecht. Alle Rechte vorbehalten. ©{" "}
                  {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
        <img className="pt-img2 animate1" src={ptImg2} alt="" />
        <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
        <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
      </footer>
    </>
  );
};

export default AboutSection;
