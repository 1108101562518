import React from "react";
import Banner from "../elements/Banner";

import anesthesia from "../../images/anesthesia.jpg";
import narkose from "../../images/narkose.jpg";
import lokale from "../../images/lokale.jpg";
import lachgas from "../../images/lachgas.jpg";
import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function Sedation() {
  return (
    <React.Fragment>
      <PageTitle title="Sedierung und Narkose" canonical="sedierung" />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Sedierung und Narkose" />

          <div className="container">
            <h4 className="mt-30 text-secondary">Lokale Betäubung</h4>
            <div className="diagnostic-text-with-image mt-30">
              <div>
                <p className="text-justify">
                  Für eine möglichst schmerzfreie Behandlung verwenden wir in
                  unserer Praxis häufig eine lokale Betäubung, um den zu
                  behandelnden Zahn "schlafen zu schicken". Vor den
                  herkömmlichen Spritzen verwenden wir ein "Zauberspray", um die
                  Einstichstelle vorab zu betäuben und unangenehme Gefühle zu
                  reduzieren. Letzteres ist keine Leistung der gesetzlichen
                  Krankenkasse und kostet in der Regel zwischen 10 und 20 Euro.
                </p>

                <p className="text-justify">
                  Bei kleinen Kindern kann die schnelle Taubheit der Lippe bei
                  der ersten Betäubung irritierend sein, und sie könnten das
                  Gefühl haben, dass ihnen etwas "weh tut". Wir erklären dann,
                  dass die Taubheit ein gutes Zeichen dafür ist, dass der Zahn
                  bereits betäubt ist.
                </p>
                <p className="text-justify">
                  Nach der Behandlung hält das Taubheitsgefühl noch etwa 1-2
                  Stunden an, was das Risiko birgt, dass sich das Kind
                  versehentlich in die Lippe beißt. Daher sollte das Kind erst
                  wieder essen, wenn die Taubheit vollständig abgeklungen ist.
                </p>
              </div>
              <img src={lokale} />
            </div>

            <div>
              <h4 className="mt-50 text-secondary">Sedierung</h4>

              <div className="diagnostic-text-with-image--reverse d-flex mt-30">
                <div>
                  <p className="text-justify">
                    Wenn Ihr Kind für eine normale Behandlung am Stuhl
                    überfordert ist, bieten wir die Möglichkeit einer Sedierung.
                    Dabei wird ein angstlösendes, dämpfendes Medikament
                    (Midazolam) verabreicht, das auch das Erinnerungsvermögen
                    für kurze Zeit ausschaltet, sodass die Behandlung für Ihr
                    Kind erträglich ist, ohne es zu traumatisieren.
                  </p>

                  <p className="text-justify">
                    Ihr Kind bleibt während der gesamten Sedierung ansprechbar
                    und wird nach der Behandlung wie nach einer Narkose für eine
                    gewisse Zeit in der Praxis überwacht. Gerne besprechen wir
                    mit Ihnen, ob eine Behandlung unter Sedierung bei Ihrem Kind
                    ratsam ist.
                  </p>
                  <p className="text-justify">
                    Die Sedierung ist eine reine Privatleistung und kostet in
                    der Regel rund 150 €.
                  </p>
                </div>
                <img src={anesthesia} />
              </div>
            </div>

            <div>
              <h4 className="mt-50 text-secondary">Lachgassiedierung</h4>
              <div className="diagnostic-text-with-image mt-30">
                <div>
                  <p className="text-justify">
                    Lachgas, auch als Lachgasanästhesie oder Lachgassedierung
                    bekannt, ist eine Form der Sedierung, die in der Zahnmedizin
                    häufig angewendet wird, um Patienten während zahnärztlicher
                    Eingriffe zu entspannen und Schmerzen zu reduzieren. Es
                    handelt sich um eine sichere und effektive Methode, die
                    sowohl für Kinder als auch für Erwachsene geeignet ist.
                  </p>
                  <p className="text-justify">
                    Die Verabreichung von Lachgas erfolgt über eine Maske, die
                    über Nase und Mund des Patienten platziert wird. Das Gas
                    wird zusammen mit Sauerstoff eingeatmet, wodurch eine
                    angenehme Entspannung und ein Gefühl der Euphorie entstehen.
                    Dabei bleibt der Patient jedoch bei vollem Bewusstsein und
                    kann auf Anweisungen des Zahnarztes reagieren.
                  </p>
                  <p className="text-justify">
                    Ein großer Vorteil von Lachgas ist seine schnelle Wirkung
                    und die Möglichkeit, die Sedierung während des gesamten
                    Eingriffs zu regulieren. Nach Abschluss des Eingriffs wird
                    das Lachgas abgestellt, und der Patient erholt sich schnell
                    und ohne Nebenwirkungen.
                  </p>
                  <p className="text-justify">
                    Lachgas eignet sich besonders für Patienten mit leichter
                    Zahnarztangst oder Unbehagen vor zahnärztlichen
                    Behandlungen. Es ermöglicht eine stressfreie Erfahrung und
                    trägt dazu bei, dass Patienten regelmäßig ihre
                    zahnärztlichen Termine wahrnehmen, was langfristig zu einer
                    besseren Mundgesundheit führt.
                  </p>
                  <p className="text-justify">
                    Die Sedierung ist eine reine Privatleistung und kostet in
                    der Regel rund 150 €.
                  </p>
                </div>
                <img
                  src={lachgas}
                  style={{
                    borderRadius: "2rem",
                    marginLeft: "2rem",
                    maxWidth: "50%",
                  }}
                />
              </div>
            </div>

            <h4 className="mt-50 text-secondary">Vollnarkose</h4>
            <div className="diagnostic-text-with-image--reverse mt-30">
              <div>
                <p className="text-justify">
                  Eine Narkose wird eingesetzt, um das Bewusstsein und das
                  Schmerzempfinden des Patienten durch einen künstlichen Schlaf
                  auszuschalten. Bei uns wird sie von Narkoseärzten
                  durchgeführt. Wir empfehlen eine Narkose in besonderen Fällen
                  und erwägen gemeinsam mit Ihnen in einem ausführlichen
                  Gespräch die Vor- und Nachteile einer Zahnbehandlung Ihres
                  Kindes unter Narkose.
                </p>

                <p>
                  Besondere Fälle, in denen eine Narkose empfohlen wird,
                  umfassen:
                </p>
                <ul className="text-justify">
                  <li className="ml-30">Kleinkinder</li>
                  <li className="ml-30">
                    Kinder mit ausgeprägter Zahnarztangst, die nicht durch
                    Sedierung beruhigt werden können
                  </li>
                  <li className="ml-30">
                    Behandlungen, die ohne Narkose nicht schmerzfrei
                    durchführbar sind
                  </li>
                  <li className="ml-30">
                    Umfangreiche Behandlungen, die mehrere Termine erfordern
                    würden und Ihr Kind traumatisieren könnten.
                  </li>
                </ul>
                <p className="text-justify">
                  Unsere Anästhesistin informiert Sie über Risiken und den
                  genauen Ablauf der Narkose. Als Eltern sind Sie dabei, bis Ihr
                  Kind eingeschlafen ist, und verbringen dann 1-2 Stunden im
                  Aufwachzimmer, bis es entlassen wird.
                </p>
                <p className="text-justify">
                  Oftmals ist die Vollnarkose eine Privatleistung und kostet
                  rund 300 €.
                </p>
              </div>
              <img
                src={narkose}
                style={{
                  borderRadius: "2rem",
                  maxWidth: "50%",
                  marginRight: "2rem",
                }}
              />
            </div>
          </div>
          <BookingButton
            classes="btn-primary mt-60 d-block mx-auto"
            text="Hier geht es zur Terminbuchung"
          />
        </section>
      </div>
    </React.Fragment>
  );
}
